import React from 'react';
import classNames from 'classnames';
import { Box, ButtonBase } from '@mui/material';
import { PowerSettingsNew } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { Token } from '@hooks/index';
import { Env } from '@functions/index';
import Login from '@pages/Admin/Login';

import { UnsafeAny } from '@ts/General';

import styles from './style.scss';

const nav = [
  { id: 'visits', label: 'Visits', path: '/' },
  { id: 'emails', label: 'Emails' },
  { id: 'qr-tags', label: 'QR-tags' },
];

type Props = {
  toolbar: UnsafeAny | Element | Element[];
  children?: UnsafeAny | Element | Element[];
};

export const AdminLayout = ({ toolbar, children }: Props) => {
  // const location = useLocation();
  const history = useHistory();

  const loggedIn = Token.useTokenValidation();

  const onLogout = () => {
    fetch(`${Env.apiUrl()}/aostudio/session/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then(() => {
        localStorage.removeItem('user-session');
        window.dispatchEvent(new Event('storage'));
      })
      .catch((error) => {
        console.error(error?.message || error);
      });
  };

  if (loggedIn === null) return <></>;

  if (!loggedIn) {
    return <Login />;
  }

  const user = JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}');
  const activeItem = nav.find((n) => location.pathname.includes(n.id)) || nav[0];

  return (
    <div className={styles.admin}>
      <div className={styles.toolbar}>
        <div className={styles.header}>
          <div className={styles.nav}>
            {nav.map((n) => (
              <ButtonBase
                key={n.id}
                classes={{ root: classNames(styles.navItem, activeItem?.id === n.id && styles.active) }}
                onClick={() => history.push(`/admin${n.path === '/' ? '' : `/${n.id}`}`)}
              >
                <span className={styles.navItemText}>{n.label}</span>
              </ButtonBase>
            ))}
          </div>
          <Box display="flex" alignItems="center" gap="1px">
            <ButtonBase classes={{ root: styles.logoutButton }}>{user?.name}</ButtonBase>
            <ButtonBase classes={{ root: styles.logoutIconButton }} onClick={() => onLogout()}>
              <PowerSettingsNew className={styles.logoutIcon} />
            </ButtonBase>
          </Box>
        </div>
        {toolbar}
      </div>
      {children}
    </div>
  );
};
