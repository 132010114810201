import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, ButtonBase } from '@mui/material';
import classNames from 'classnames';

import translate from '@localise/translate';

import { Font } from '@hooks/index';

import backgroundBase64 from '@assets/base64bg';
import bgMain from '@images/ao-bg-01.jpg';
import bg2 from '@images/ao-bg-02.jpg';
import bg3 from '@images/ao-bg-03.jpg';
import bg4 from '@images/ao-bg-04.jpg';
import bg5 from '@images/ao-bg-05.jpg';
import bg6 from '@images/ao-bg-06.jpg';
/* import bg7 from '@images/ao-bg-07.jpg';
import bg8 from '@images/ao-bg-08.jpg';
import bg9 from '@images/ao-bg-09.jpg';
import bg10 from '@images/ao-bg-10.jpg'; */

import Portfolio from '@pages/Portfolio';
import PortfolioAbout from '@pages/PortfolioAbout';

import projects from '@assets/projects';
import settings from '@assets/settings';

import { getPreviewImageUrl } from '@common/ProjectPreview';

import styles from './style.scss';

const staticBackgrounds: Record<number, any> = { 1: bgMain, 2: bg2, 3: bg3, 4: bg4, 5: bg5, 6: bg6 /* 7: bg7, 8: bg8, 9: bg9, 10: bg10 */ };

const Home = (): JSX.Element => {
  const history = useHistory();
  const [isBgLoaded, setIsBgLoaded] = useState(false);
  const [activeBgIndex, setActiveBgIndex] = useState(0);
  const fontLoaded = Font.useFonts();

  const backgrounds: Record<number, any> = useMemo(() => {
    if (settings.isBackgroundMapped) {
      const mappedBackgrounds: Record<number, any> = {
        1: bgMain,
      };

      projects
        .filter((p) => !p.ignoreAsBackground)
        .forEach((p, idx) => {
          mappedBackgrounds[idx + 2] = getPreviewImageUrl({ large: true }, p.images.previewNumber);
        });

      return mappedBackgrounds;
    }

    return staticBackgrounds;
  }, [settings.isBackgroundMapped]);

  useEffect(() => {
    let newActiveBgIndex = activeBgIndex + 1;

    if (newActiveBgIndex >= Object.keys(backgrounds).length) {
      newActiveBgIndex = 0;
    }

    let timeout: ReturnType<typeof setTimeout>;

    if (isBgLoaded) {
      timeout = setTimeout(() => setActiveBgIndex(newActiveBgIndex), settings.backgroundTime * 1000);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [activeBgIndex, isBgLoaded]);

  const t = {
    title: translate('home.title'),
    description: translate('home.description'),
    descriptionActionButton: translate('home.descriptionActionButton'),
    leftSideTitle: translate('home.leftSide.title'),
    leftSideDescription: translate('home.leftSide.description'),
  };

  const isActive = (index: number) => activeBgIndex - 1 === index;

  return (
    <>
      <header className={styles.header}>
        <div className={styles.bgHolder}>
          {!isBgLoaded && <img className={styles.bg} src={backgroundBase64} alt="backgroundImage" />}
          <img
            className={classNames(styles.bg, styles.background, activeBgIndex === 0 && styles.active)}
            src={bgMain}
            alt="background x-1"
            onLoad={() => setIsBgLoaded(true)}
          />
          <>
            {isBgLoaded &&
              Array.from({ length: Object.keys(backgrounds).length - 1 }, (_item, idx) => (
                <img
                  key={idx}
                  className={classNames(styles.bg, styles.background, isActive(idx) && styles.active)}
                  src={backgrounds[idx + 2]}
                  alt="background x-2"
                />
              ))}
          </>
          <div className={styles.overlay} />
        </div>
        <div className={classNames(styles.textHolder, fontLoaded && styles.loaded)}>
          {t.title && (
            <Typography variant="h1" className={styles.title} mb={2}>
              {t.title}
            </Typography>
          )}
          {t.description && (
            <Typography variant="h5" mt={1} mb={1} className={styles.description}>
              {t.description}
            </Typography>
          )}
          {t.descriptionActionButton && (
            <ButtonBase classes={{ root: styles.buttonAction }} onClick={() => history.push('/contact')}>
              <Typography className={styles.buttonText}>{t.descriptionActionButton}</Typography>
            </ButtonBase>
          )}
          {t.leftSideTitle && (
            <div className={styles.leftSide}>
              {t.leftSideTitle && <h1 className={styles.leftSideTitle}>{t.leftSideTitle}</h1>}
              {t.leftSideDescription && <h5 className={styles.leftSideDescription}>{t.leftSideDescription}</h5>}
            </div>
          )}
        </div>
      </header>
      <PortfolioAbout />
      <Portfolio />
    </>
  );
};

export default Home;
// dep
