// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.admin-OWr9i{display:flex;flex-direction:column;min-height:100dvh}.nav-5c1uW{display:flex}.navItem-NdXAJ{padding:6px 12px !important;border-radius:5px !important}.navItemText-OEo77{font-size:14px;flex:1;color:#999;text-align:center;white-space:nowrap;letter-spacing:1px;text-transform:uppercase}.active-Yb54J .navItemText-OEo77{color:#55594a;font-weight:bolder}.toolbar-PKOba{border-radius:10px;min-width:180px;color:#444;width:100%;padding:30px;box-sizing:border-box}.toolbar-PKOba .header-GE67U{display:flex;align-items:center;justify-content:space-between}.toolbar-PKOba .header-GE67U .title-N4u64{font-size:30px;opacity:.2}.logoutButton-P-gSe,.logoutIconButton-pQMyQ{padding:10px 20px !important;font-size:14px !important;color:#fff !important;background:#444 !important;min-height:40px;box-shadow:0 2px 20px 0 rgba(0,0,0,.4)}.logoutButton-P-gSe:first-child,.logoutIconButton-pQMyQ:first-child{border-top-left-radius:20px !important;border-bottom-left-radius:20px !important}.logoutButton-P-gSe:last-child,.logoutIconButton-pQMyQ:last-child{border-top-right-radius:20px !important;border-bottom-right-radius:20px !important}.logoutIconButton-pQMyQ{padding:0 12px 0 10px !important}.logoutIcon-oLpfL{font-size:20px !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin": "admin-OWr9i",
	"nav": "nav-5c1uW",
	"navItem": "navItem-NdXAJ",
	"navItemText": "navItemText-OEo77",
	"active": "active-Yb54J",
	"toolbar": "toolbar-PKOba",
	"header": "header-GE67U",
	"title": "title-N4u64",
	"logoutButton": "logoutButton-P-gSe",
	"logoutIconButton": "logoutIconButton-pQMyQ",
	"logoutIcon": "logoutIcon-oLpfL"
};
export default ___CSS_LOADER_EXPORT___;
