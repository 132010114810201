// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "button{border:none}.dialogContent-k4Cua{min-width:400px;padding:10px 20px}.dialogHeader-y-gtq{padding:10px 20px}.subHeader-i5RhI{display:flex;justify-content:space-between;align-items:center;padding:24px 0 48px}.sectionPart-xnFaB{min-width:100px}.sectionTitle-60iYa{font-size:24px;flex:1;color:#444;text-align:center}.content-02J5E{background:#fff;flex:1;display:flex;flex-direction:column;justify-content:space-between}.foot-wVBQg{display:flex;justify-content:center}.dataGridWrap-6bYli{margin-top:-32px}.ident-mensi{opacity:.3;font-size:10px}.fade--Wj4t{opacity:.4}.notRelevant-mEcFc{color:#aaa;text-decoration:line-through}.rank1-zWz9f,.rank2-ZR4wE,.rank3-z8B6-{display:flex;align-items:center;font-weight:bold;color:#616652;border-radius:6px;transform:translateX(-10px)}.iconGroup-M-4MW{display:flex;flex-direction:column}.icon-KTiS-:not(:first-child){margin-top:-20px}.page-AFkiv{padding:2px 6px;border:1px solid #eee;border-radius:5px;font-size:10px;text-transform:uppercase;letter-spacing:1px}.nowrap-RnlNr{white-space:nowrap}.detail-17IiO{padding:20px;display:flex;flex-direction:column}.message-BkNw4{display:flex;flex-direction:column;align-self:center;gap:12px;max-width:800px}.text-l00-d{font-size:14px;line-height:1.4}.highlight-GBXZk{font-weight:bolder}.qr-b20Ng{width:400px;height:400px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogContent": "dialogContent-k4Cua",
	"dialogHeader": "dialogHeader-y-gtq",
	"subHeader": "subHeader-i5RhI",
	"sectionPart": "sectionPart-xnFaB",
	"sectionTitle": "sectionTitle-60iYa",
	"content": "content-02J5E",
	"foot": "foot-wVBQg",
	"dataGridWrap": "dataGridWrap-6bYli",
	"ident": "ident-mensi",
	"fade": "fade--Wj4t",
	"notRelevant": "notRelevant-mEcFc",
	"rank1": "rank1-zWz9f",
	"rank2": "rank2-ZR4wE",
	"rank3": "rank3-z8B6-",
	"iconGroup": "iconGroup-M-4MW",
	"icon": "icon-KTiS-",
	"page": "page-AFkiv",
	"nowrap": "nowrap-RnlNr",
	"detail": "detail-17IiO",
	"message": "message-BkNw4",
	"text": "text-l00-d",
	"highlight": "highlight-GBXZk",
	"qr": "qr-b20Ng"
};
export default ___CSS_LOADER_EXPORT___;
