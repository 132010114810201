export default {
  title: 'ao studio',
  logo: 'ao studio',
  nav: {
    home: 'domov',
    aboutUs: 'o štúdiu',
    services: 'služby',
    portfolio: 'portfólio',
    contact: 'kontakt',
  },
  foot: {
    title: '',
  },
  home: {
    title: '',
    description: '',
    descriptionActionButton: '',
    leftSide: {
      title: 'ao studio',
      description: 'štúdio interiérového designu',
    },
  },
  portfolio: {
    title: 'portfólio projektov',
    // subTitle: 'Od predstavy po realitu.',
    showMore: 'zobraziť viac',
  },
  aboutUs: {
    title: 'ao studio',
    subTitle: 'Volám sa Annette Oberfranczová,',
    description:
      'som architektka a hoci pod vlastnou značkou "ao studio" navrhujem až od roku 2023, skúsenosti s navrhovaním som získala praxou už počas vysokej školy v rôznych architektonických ateliéroch, tak aj niekoľko ročnou praxou po doštudovaní. Architektúre, navrhovaniu a designu sa venujem v podstate už od svojho skorého detstva, kedy som dlhodobo navštevovala základnú umeleckú školu, ktorá ma neskôr doviedla až po štúdium na FA ČVUT v  Prahe.\n\nAktuálne sa venujem návrhom rezidenčných aj komerčných interiérov všetkých rozmerov. Navrhujem interiéry novostavieb alebo rekonštrukcie stávajúcich interiérov. Každý návrh sa čo najviac snažím prispôsobiť Vašim predstavám, no pristupujem k nemu akoby bol môj vlastný. Potrpím si na tom, aby bol každý návrh doladený do posledného detailu. Praktickosť a funkčnosť interiéru je u mňa na prvom mieste no dbám na jedinečnosť každého projektu.\n\nHoci sa návrh interiéru môže javiť ako banalita, správna ergonómia priestoru, farebnosť, osvetlenie a celková estetika interiéru hrajú kľúčovú úlohu vo vytvorení funkčného a pohodlného prostredia, ktoré je zásadné pre našu psychickú a fyzickú pohodu. Návrh interiéru od architekta je preto krok k lepšiemu bývaniu :).',
    parts: [
      {
        title: 'služby',
        type: 'PACKAGES',
        items: [
          /*
          {
            id: 'BASIC',
            title: 'BASIC',
            items: ['Úvodné stretnutie', 'Mood board interiéru', 'Pôdorys s rozvrhnutím interiéru'],
            //price: '20€ / 500kč na m²', '10€ / 250kč na m²'
          }, */
          {
            id: 'VISION',
            title: 'Návrh interiéru VISION',
            items: ['moodboard interiéru', 'materiálové riešenie interiéru', 'pôdorys s dispozičným riešením', 'fotorealistické vizualizácie'],
            //price: '25 - 35€ / 650 - 900kč na m²'
          },
          {
            id: 'STANDARD',
            title: 'Návrh interiéru STANDARD',
            items: [
              'moodboard interiéru',
              'materiálové riešenie interiéru',
              'pôdorys s dispozičným riešením',
              'fotorealistické vizualizácie',
              'dokumentácia k návrhu interiéru',
              'výpis zariaďovacích produktov',
            ],
            //price: '35 - 50€ / 900 - 1250kč na m²'
          },
          {
            id: 'REALIZÁCIA',
            title: 'REALIZACIA interiéru na kľúč',
            //subtitle: '(v náväznosti na návrh interiéru)',
            items: [
              'návrh interiéru STANDARD',
              'položkový rozpočet',
              'vzorkovanie materiálov',
              'spoločné návštevy showroomov',
              'dohľad nad realizáciou na stavbe',
            ],
            //price: 'od 50€ / 1250kč na m²'
          },
          {
            id: 'PORADENSTVO',
            title: 'PORADENSTVO',
            items: [
              'poskytovanie odborných rád a návrhov týkajúcich sa estetiky, funkčnosti alebo usporiadania interiéru',
              'pomoc pri zariaďovaní nového interiéru, klientských zmenách developerských projektov alebo rekonštrukcii',
            ],
            //price: '35€ / 900kč na hod',
          },
        ],
        //  tip: '* Balíčky je možné kombinovať alebo prispôsobovať podľa projektu, pretože každý projekt je jedinečný.', //\n* Minimálna cena za návrh je 300€ / 7500kč.
        contactButton: 'mám záujem',
      },
      {
        title: 'Priebeh návrhu interiéru',
        type: 'IDEA',
        items: [
          {
            title: 'Cenová ponuka',
            description:
              'Na základe vašich podkladov k projektu - pôdorys a m2, Vám vystavím nezáväznú cenovú ponuku v požadovanom rozsahu návrhu a dokumentácie.',
          },
          {
            title: 'Úvodné stretnutie',
            description:
              '\nPo odsúhlasení cenovej ponuky nasleduje spoločné stretnutie, na ktorom sa porozprávame o Vašich predstavách nového interiéru, čo sa Vám páči a čo naopak nie, čo Vás baví, čomu sa venujete, ako funguje Váš každodenný život, aby bol návrh prispôsobený a napasovaný naozaj na Vás.\n\nPo prvotnom stretnutí a pred započatím prác na samotnom návrhu si daný priestor najskôr obhliadnem a zameriam. Pokiaľ obhliadka a zameranie priestoru nie je možné, napríklad v prípade novostavby, je možné pracovať s projektovou dokumentáciou. V tomto prípade sú všetky rozmery vo výkresovej dokumentácií orientačné a pred realizáciou je nutné ich overiť podľa skutočného stavu na stavbe.',
          },
          {
            title: 'Dispozičné riešenie a moodboard interiéru',
            subtitle: '1 - 2 týždne',
            description:
              '\nPo obhliadke a zameraní priestoru následuje návrh dispozičného riešenia interiéru a vytvorenie moodboardu. Dispozičné riešenie a moodboard sú ťažiskovými prvkami celého návrhu. Dispozičné riešenie je spracované vo variantách s odprezentovaním plusov a mínusov každej varianty. Moodboard je koláž inšpiratívnych obrázkov, produktov, materiálov a farieb vybraných na základe Vami prezentovaných predstáv, ktoré by sa mali v návrhu objaviť.',
          },
          {
            title: 'Návrh interiéru',
            subtitle: '4 - 16 týždňov',
            description:
              '\nPo doladení a spoločnom odsúhlasení dispozičného riešenia a moodboardu interiéru, pokračujem na spracovaní celého návrhu interiéru. Kompletný návrh interiéru je spracovaný v projektovej dokumentácií, ktorá obnáša rôzne pôdorysy (napríklad pôdorys návrhu elektro, pôdorys návrhu osvetlenia, pôdorys návrhu povrchov atp.), samostatné pôdorysy miestností, detailné pohľady na všetky steny každej miestnosti, realistické vizualizácie všetkých miestností a tabuľku s výpisom základného typového vybavenia. V prípade realizačného projektu je dokumentácia rozšírená o položkový rozpočet. Každý návrh budeme spolu priebežne konzultovať, aby sme dospeli k správnemu koncu a hlavne k Vašej spokojnosti s návrhom.',
          },
          {
            title: 'Realizácia',
            description:
              'Po dokončení návrhu Vás prevediem celým procesom realizácie. Súčasťou realizácie je aj spoločné navštívenie showroomov, kde vyberieme konkrétne nábytky alebo konkrétne materiály na základe predvybraných produktov z návrhu. K vybraným produktom vypracujem položkový rozpočet, ktorý dám naceniť jednotlivým dodávateľom. Následne za Vás vybavím objednávky aj dodávky materiálu na stavbu. Skoordinujem jednotlivé práce na stavbe a odkontrolujem správnosť riešení podľa spoločne odsúhlasenej dokumentácie. Takýmto spôsobom docielime zrealizovanie toho, čo bolo doposiaľ len na papieri.',
          },
        ],
      },
    ],
  },
  aboutProject: {
    title: 'Každý priestor má svoj príbeh.',
    subTitle: 'Spoločne vytvoríme ten Váš.',
    showMore: 'Zobraziť dalšie projekty',
  },
  project: {
    xNotFound: 'Projekt nebol nájdený',
    backToPortfolio: 'späť do portfólia',
    previousProject: 'predchádzajúci projekt',
    nextProject: 'ďalší projekt',
    mainImage: 'výkresy',
  },
  contact: {
    title: 'kontakt',
    subTitle: 'Premýšľate nad návrhom interiéru? Kontaktujte ma s Vašimi dotazmi, rada Vám na ne odpoviem.',
    form: {
      title: 'Kontaktný formulár',
      name: 'meno a priezvisko',
      email: 'e-mail',
      subject: 'predmet správy',
      phone: 'telefónne číslo',
      message: 'správa',
      sendMessage: 'odoslať',
      sendMessageOk: 'Vaša správa bola úspešne odoslaná. Verím, že spoločne vytvoríme Váš nový domov.',
    },
    prefillSubjectFromAboutUsLink: 'Mám záujem o $PACKAGE',
    extras: [
      [
        { label: 'e-mail:', value: 'oberfranczova@aostudio.sk' },
        { label: 'telefón - sk:', value: '+421 907 913 529' },
        { label: 'telefón - cz:', value: '+420 725 137 126' },
      ],
      [
        { label: 'IČO:', value: '09967346' },
        { label: 'DIČ:', value: 'CZ9661121877' },
        { label: '', value: 'neplátca DPH' },
      ],
    ],
  },
  client: {
    name: 'Annette Oberfranczová',
    fullName: 'Ing. arch. Annette Oberfranczová',
    contact1: '+421 907 913 529',
    contact2: '+420 725 137 126',
  },
};
