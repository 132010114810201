import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Dialog, Fade, TextField } from '@mui/material';

import { Env } from '@functions/index';

import { Token } from '@hooks/index';

import { AdminLayout } from '@layout/Admin/AdminLayout';
import DataGrid from '@common/DataGrid/DataGrid';
import Button from '@common/Button';
import Time from '@functions/time';

import styles from './style.scss';

type QRTag = {
  _id: string;
  name: string;
  identifier: string;
  text: string;
  createdAt: string;
};

const AdminQrTags = (): JSX.Element => {
  const [data, setData] = useState<QRTag[] | null>(null);
  const [loading, setLoading] = useState(true);

  const [update, setUpdate] = useState<Partial<QRTag>>({});
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);

  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detail, setDetail] = useState<QRTag | null>(null);

  const [text, setText] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [identifier, setIdentifier] = useState<string>('');

  const [isGenerating, setIsGenerating] = useState<boolean>(false);

  const history = useHistory();

  const loggedIn = Token.useTokenValidation();

  const isLinkValid = text.length > 0 && text.includes('.') && !text.endsWith('.');

  useEffect(() => {
    loadData();
  }, [loggedIn]);

  const loadData = () => {
    fetch(`${Env.apiUrl()}/aostudio/tags/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
      body: JSON.stringify({
        sortDir: 'DESC',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then((resp) => {
        setData(resp);
        setLoading(false);
      })
      .catch((error) => {
        if (Env.isLocalhost()) {
          // setData();
        }

        if (error.statusCode === 401) {
          localStorage.removeItem('user-session');
          window.dispatchEvent(new Event('storage'));
        }
      });
  };

  const onCreateTag = () => {
    setIsGenerating(false);

    fetch(`${Env.apiUrl()}/aostudio/tags`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
      body: JSON.stringify({
        name,
        identifier,
        text,
      }),
    }).then(() => {
      loadData();
    });
  };

  const onUpdateTag = () => {
    setIsUpdateOpen(false);
    fetch(`${Env.apiUrl()}/aostudio/tags/${update?._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
      body: JSON.stringify({
        name: update?.name,
      }),
    })
      .then(() => {
        loadData();
      })
      .catch((err) => {
        alert(err?.message);
      });
  };

  const onDeleteTag = (id: string) => {
    fetch(`${Env.apiUrl()}/aostudio/tags/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
    }).then(() => {
      loadData();
    });
  };

  const config = [
    { id: 'id', label: 'identifier', render: (row: QRTag) => <div className={styles.fade}>{row.identifier}</div> },
    {
      id: 'name',
      label: 'Name',
      render: (row: QRTag) => row.name,
    },
    {
      id: 'uniqueVisitors',
      label: 'Visitors',
      render: (row: QRTag) => (row.identifier ? row.count || 0 : '-'),
    },
    {
      id: 'Pages',
      label: 'Pages visited',
      render: (row: QRTag) => (row.identifier ? row.count || 0 : '-'),
    },
    { id: 'createdAt', label: 'Created at', render: (row: QRTag) => Time.formatDate(row.createdAt) },
    {
      id: 'actions',
      label: 'Actions',
      render: (row: QRTag) => (
        <Box display="flex" gap={2}>
          <Button
            size="small"
            variant="outlined"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setUpdate(row);
              setIsUpdateOpen(true);
            }}
          >
            Edit
          </Button>
          <Button
            size="small"
            color="error"
            variant="outlined"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onDeleteTag(row._id);
            }}
          >
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <AdminLayout
      toolbar={
        <div className={styles.subHeader}>
          <div className={styles.sectionTitle}>QR Code tags</div>
        </div>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" mb={4}>
        <Box minWidth="400px" display="flex" flexDirection="column">
          <TextField label="Text / Link to generate QR Code" value={text} onChange={(e) => setText(e.target.value)} sx={{ mb: 2 }} />
          {isLinkValid && isGenerating && (
            <TextField label="Identifier" value={identifier} onChange={(e) => setIdentifier(e.target.value)} sx={{ mb: 2 }} />
          )}
          {!isGenerating ? (
            <Fade in={!!text}>
              <Box minWidth="400px" display="flex" flexDirection="column" my={2}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setIsGenerating(true);
                    //generate 2digit string
                    if (isLinkValid) {
                      setIdentifier(Math.round(Math.random() * 100).toString());
                    } else {
                      setIdentifier('');
                    }
                  }}
                >
                  Generate QR code
                </Button>
              </Box>
            </Fade>
          ) : (
            <>
              <QRCode value={identifier ? text + '#qr' + identifier : text} viewBox={`0 0 400 400`} width="400" height="400" className={styles.qr} />
              <TextField label="Save as" value={name} onChange={(e) => setName(e.target.value)} sx={{ mt: 2 }} />
              <Button sx={{ mt: 2 }} color="primary" variant="contained" onClick={() => onCreateTag()}>
                Save QR tag to watch
              </Button>
            </>
          )}
        </Box>
      </Box>
      <div className={styles.subHeader}>
        <div className={styles.content}>
          <div className={styles.dataGridWrap}>
            {data ? (
              <DataGrid<QRTag>
                data={data}
                config={config}
                onRowClick={(row) => {
                  setDetail(row);
                  setIsDetailOpen(true);
                }}
              />
            ) : (
              <Box sx={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={30} color="secondary" />
              </Box>
            )}
          </div>
          <div className={styles.foot}>
            <Button onClick={() => history.push('/')}>Go to web</Button>
          </div>
        </div>
      </div>
      <Dialog open={isDetailOpen} onClose={() => setIsDetailOpen(false)}>
        <div className={styles.dialogContent}>
          <div className={styles.dialogHeader}>
            <div className={styles.sectionTitle}>{detail?.name}</div>
            <Box my={2} sx={{ textAlign: 'center' }}>
              <div className={styles.fade}>{detail?.identifier}</div>
              <div className={styles}>{detail?.text}</div>
            </Box>
            {detail && (
              <QRCode
                value={detail?.identifier ? detail?.text + '#qr' + detail?.identifier : detail.text}
                viewBox={`0 0 400 400`}
                width="400"
                height="400"
                className={styles.qr}
              />
            )}
          </div>
        </div>
      </Dialog>
      <Dialog open={isUpdateOpen} onClose={() => setIsUpdateOpen(false)}>
        <div className={styles.dialogContent}>
          <div className={styles.dialogHeader}>
            <div className={styles.sectionTitle}>Update QR Tag</div>
            <Box my={2} display="flex" flexDirection="column">
              <TextField label="Name" value={update?.name} onChange={(e) => setUpdate({ ...update, name: e.target.value })} sx={{ mb: 2 }} />
              {identifier && <TextField label="Identifier" value={update?.identifier} disabled />}
              <TextField label="Text" value={update?.text} disabled />
              <Button color="primary" variant="contained" onClick={() => onUpdateTag()} sx={{ mt: 2 }}>
                Update QR tag
              </Button>
            </Box>
          </div>
        </div>
      </Dialog>
    </AdminLayout>
  );
};

export default AdminQrTags;
