import { Env } from '@functions/index';
import React, { useState } from 'react';
import PageWrap from '@layout/PageWrap';
import Typography from '@mui/material/Typography';
import { Box, Button, TextField } from '@mui/material';

const Login = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);

    fetch(`${Env.apiUrl()}/aostudio/session/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
      },
      body: JSON.stringify({ password }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then((resp) => {
        localStorage.setItem('user-session', JSON.stringify(resp));
        window.dispatchEvent(new Event('storage'));
      })
      .catch((error) => {
        setError(error?.message || error);
        setLoading(false);
      });
  };

  return (
    <PageWrap>
      <Typography variant="h5" color="primary" textAlign="center">
        ao-studio admin
      </Typography>
      <Box display="flex" flexDirection="column" minWidth={300} mt={4}>
        <TextField
          label="Password"
          onChange={(e) => {
            setPassword(e.target.value);
            if (error) setError(null);
          }}
          value={password}
          type="password"
          error={!!error}
          helperText={error}
          onKeyPress={(e) => e.key === 'Enter' && onSubmit()}
        />
        <Button variant="contained" onClick={onSubmit} sx={{ mt: 2 }} disabled={loading}>
          Confirm
        </Button>
      </Box>
    </PageWrap>
  );
};

export default Login;
