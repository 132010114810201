import styles from '@pages/Project/style.scss';
import classNames from 'classnames';
import React, { useState } from 'react';
import { getFullSrc, getSmallSrc, PortfolioImage } from '@pages/Project';
import ToolbarButton from '@common/ToolbarButton';

type Props = {
  images: string[];
  onOpenImage: (image: string) => void;
  isReady: boolean;
  aspectRatio?: string;
  offsetY?: number;
};

const Gallery = ({ images, onOpenImage, aspectRatio, isReady, offsetY }: Props): JSX.Element => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const [activeIndex, setActiveImage] = useState(0);

  const renderMainImage = (image: string) => {
    return (
      <div style={{ marginTop: `${offsetY || 0}px` }} key={image}>
        <button
          className={styles.galleryItemFull}
          style={{ aspectRatio: aspectRatio || 'initial' }}
          onClick={() => onOpenImage(image)}
          // onKeyDown={(e) => Events.onKeyDown(e, () => onOpenImage())}
        >
          <PortfolioImage
            className={classNames(styles.galleryItemImageLoading, isImageLoaded && styles.hidden)}
            alt="preview"
            src={getSmallSrc(image)}
          />
          {isReady && (
            <PortfolioImage className={styles.galleryItemImage} onLoad={() => setIsImageLoaded(true)} alt="preview" src={getFullSrc(image)} />
          )}
        </button>
      </div>
    );
  };

  return (
    <div className={styles.mainImageWrap}>
      {activeIndex !== 0 && <ToolbarButton withPosition icon="back" onClick={() => setActiveImage(activeIndex - 1)} />}
      {renderMainImage(images[activeIndex])}
      {activeIndex !== images.length - 1 && <ToolbarButton withPosition icon="forward" onClick={() => setActiveImage(activeIndex + 1)} />}
    </div>
  );
};

export default Gallery;
