import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, CircularProgress, Dialog, Fade, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

import { Env } from '@functions/index';

import { Token } from '@hooks/index';

import { AdminLayout } from '@layout/Admin/AdminLayout';
import DataGrid from '@common/DataGrid/DataGrid';
import Button from '@common/Button';
import Time from '@functions/time';

import { EmailMessage } from '@ts/Email';

import styles from './style.scss';

const dummyData: EmailMessage[] = [
  {
    email: 'oberfranczova@gmail.com',
    _id: '6621093803821308a0827b10',
    name: 'Annette',
    subject: 'Návrh interiéru',
    message: 'Dobrý deň,\nChcela by som sa opýtať, či potrebujete na vypracovanie cenovej ponuku na návrh interiéru?',
    createdAt: '2024-04-18T11:51:20.921Z',
    updatedAt: '2024-04-18T11:51:20.921Z',
    __v: 0,
  },
];

const AdminEmailMessages = (): JSX.Element => {
  const [data, setData] = useState<EmailMessage[] | null>(null);
  const [loading, setLoading] = useState(true);
  // const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detail, setDetail] = useState<EmailMessage | null>(null);

  const history = useHistory();

  const loggedIn = Token.useTokenValidation();

  useEffect(() => {
    loadData();
  }, [loggedIn]);

  const loadData = () => {
    fetch(`${Env.apiUrl()}/aostudio/email/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
      body: JSON.stringify({
        sortDir: 'DESC',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then((resp) => {
        setData(resp);
        setLoading(false);
      })
      .catch((error) => {
        if (Env.isLocalhost()) {
          setData(dummyData);
        }

        if (error.statusCode === 401) {
          localStorage.removeItem('user-session');
          window.dispatchEvent(new Event('storage'));
        }
      });
  };

  const config = [
    { id: 'createdAt', label: 'Accepted', render: (row: EmailMessage) => Time.formatDate(row.createdAt) },
    // { id: 'id', label: 'ID', render: (row: EmailMessage) => row._id },
    {
      id: 'email',
      label: 'Email',
      render: (row: EmailMessage) => (
        <div>
          <div className={styles.highlight}>{row.email}</div>
          <div className={styles.fade}>{row.name}</div>
        </div>
      ),
    },
    { id: 'subject', label: 'Subject', render: (row: EmailMessage) => <div className={styles.highlight}>{row.subject}</div> },
    {
      id: 'message',
      label: 'Message',
      render: (row: EmailMessage) => (row.message.length > 100 ? row.message.substring(0, 140) + '...' : row.message),
    },
  ];

  return (
    <AdminLayout
      toolbar={
        <div className={styles.subHeader}>
          <div className={styles.sectionPart}>
            <Fade in={!!detail}>
              <IconButton onClick={() => setDetail(null)}>
                <ArrowBack />
              </IconButton>
            </Fade>
          </div>
          <div className={styles.sectionTitle}>Emails</div>
          <div className={styles.sectionPart}></div>
        </div>
      }
    >
      <div className={styles.subHeader}>
        <div className={styles.content}>
          {detail ? (
            <div className={styles.detail}>
              <div className={styles.message}>
                <div className={styles.highlight}>{detail.email}</div>
                <div className={styles.fade}>{detail.name}</div>
                <div className={styles.highlight}>{detail.subject}</div>
                <div className={styles.text}>{detail.message}</div>
              </div>
            </div>
          ) : (
            <div className={styles.dataGridWrap}>
              {data ? (
                <DataGrid<EmailMessage> data={data} config={config} onRowClick={(row) => setDetail(row)} />
              ) : (
                <Box sx={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size={30} color="secondary" />
                </Box>
              )}
            </div>
          )}
          <div className={styles.foot}>
            <Button onClick={() => history.push('/')}>Go to web</Button>
          </div>
        </div>
      </div>
      {/*
      <Dialog open={isDetailOpen} onClose={() => setIsDetailOpen(false)}>
        <div className={styles.dialogContent}>
          <div className={styles.dialogHeader}>
            <div className={styles.sectionTitle}>EmailMessage DETAIL</div>
          </div>
        </div>
      </Dialog>
      */}
    </AdminLayout>
  );
};

export default AdminEmailMessages;
