import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Box, ButtonBase, Checkbox, CircularProgress, Dialog, Fade, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { ArrowBack, PowerSettingsNew } from '@mui/icons-material';
import { format, differenceInMinutes, differenceInSeconds } from 'date-fns';
import uniqBy from 'lodash.uniqby';

import { Env, Referrer, Time } from '@functions/index';
import { Token } from '@hooks/index';

import Login from '@pages/Admin/Login';
import DataGrid from '@common/DataGrid/DataGrid';
import Rank from '@common/DataGrid/components/Rank';

import styles from './style.scss';

import { SessionByDate, SessionsData, UniqueVisitDetail } from '@ts/Visit';
import includes from 'lodash.includes';
import Button from '@common/Button';
import { useHistory } from 'react-router-dom';

const dummyData = {
  sessionsByDate: [
    {
      pageVisits: 84,
      uniqueVisits: 60,
      uniqueVisitDetails: [
        {
          identifier: 'c290dc9a-925d-407d-81fa-0474eadc684f',
          count: 1,
          visits: [
            {
              _id: '668c4056891ece001bf4d7dc',
              identifier: 'c290dc9a-925d-407d-81fa-0474eadc684f',
              page: '/',
              url: '/aostudio/visits',
              ip: '172.68.213.124',
              userAgent:
                'Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21F90 Instagram 338.0.0.23.94 (iPhone15,2; iOS 17_5_1; sk_SK; sk; scale=3.00; 1179x2556; 617103803; IABMV/1)',
              referer: 'https://aostudio.sk/',
              createdAt: '2024-07-08T19:39:02.481Z',
              updatedAt: '2024-07-08T19:39:02.481Z',
              __v: 0,
            },
          ],
        },
        {
          identifier: 'c5bb892f-0271-4259-ae1d-f493efd28bea',
          count: 1,
          visits: [
            {
              _id: '668c406f891ece001bf4d7e2',
              identifier: 'c5bb892f-0271-4259-ae1d-f493efd28bea',
              page: '/services',
              url: '/aostudio/visits',
              ip: '172.68.213.124',
              userAgent:
                'Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.5 Mobile/15E148 Safari/604.1',
              referer: 'https://aostudio.sk/',
              createdAt: '2024-07-08T19:39:27.502Z',
              updatedAt: '2024-07-08T19:39:27.502Z',
              __v: 0,
            },
          ],
        },
      ],
      day: '2021-01-01',
    },
  ],
  uniqueVisitsTotal: 132,
  pageVisitsTotal: 203,
};

const statsAdapter = (data: SessionsData): SessionsData => {
  // set last visit as not relevant if time of previous visit exceeds 5min
  data.sessionsByDate.forEach((d) => {
    d.uniqueVisitDetails.forEach((u) => {
      const lastVisit = u.visits[u.visits.length - 1];
      const prevVisit = u.visits[u.visits.length - 2];

      if (lastVisit && prevVisit) {
        const diff = differenceInSeconds(new Date(lastVisit.createdAt), new Date(prevVisit.createdAt));
        if (diff > 300) {
          lastVisit.relevant = false;
        }
      }
    });
  });

  return data;
};

const Admin = (): JSX.Element => {
  const [data, setData] = useState<SessionsData | null>(null);
  const [loading, setLoading] = useState(true);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [detail, setDetail] = useState<UniqueVisitDetail | null>(null);

  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [showRelevant, setShowRelevant] = useState(localStorage.getItem('adminVisitsShowRelevant') === 'true' || false);

  const history = useHistory();

  const loggedIn = Token.useTokenValidation();

  useEffect(() => {
    loadData();
  }, [loggedIn]);

  const loadData = () => {
    fetch(`${Env.apiUrl()}/aostudio/visit-stats`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
      body: JSON.stringify({
        period: 'week',
        sortDir: 'DESC',
      }),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then((resp) => {
        setData(statsAdapter(resp));
        setLoading(false);
      })
      .catch((error) => {
        if (Env.isLocalhost()) {
          setData(dummyData);
        }

        if (error.statusCode === 401) {
          localStorage.removeItem('user-session');
          window.dispatchEvent(new Event('storage'));
        }
      });
  };

  const onLogout = () => {
    fetch(`${Env.apiUrl()}/aostudio/session/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': 'aostudio2xAostudio93sL0z',
        authorization: `Bearer ${JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}').token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((error) => {
            throw error;
          });
        }
        return response.json();
      })
      .then(() => {
        localStorage.removeItem('user-session');
        window.dispatchEvent(new Event('storage'));
      })
      .catch((error) => {
        console.error(error?.message || error);
      });
  };

  if (loggedIn === null) return <></>;

  if (!loggedIn) {
    return <Login />;
  }

  const isBot = (page: string) => includes(page, 'apple-app-site-association');

  const getRank = (row: UniqueVisitDetail): number => {
    const uniqVisits = uniqBy(row?.visits, 'page').length;
    let rank = 0;

    if (row.visits.some((v) => isBot(v.page))) return 0;

    if (uniqVisits > 8) rank = 3;
    else if (uniqVisits > 4) rank = 2;
    else if (uniqVisits > 1) rank = 1;

    return rank;
  };

  const user = JSON.parse((localStorage.getItem('user-session') as string | undefined) || '{}');

  const config = [
    { id: 'day', width: 1, render: (row: SessionByDate) => Time.formatDate(row.day) },
    { id: 'rank', render: (row: SessionByDate) => row.uniqueVisitDetails.reduce((acc, r) => acc + getRank(r), 0) },
    { id: 'uniqueVisits', label: 'Unique visitors' },
    { id: 'relevantVisits', label: 'Relevant visitors', render: (row: SessionByDate) => row.uniqueVisitDetails.filter((r) => getRank(r)).length },
    { id: 'pageVisits', label: 'Pages visited' },
  ];

  const dayConfig = [
    {
      id: 'rank',
      render: (row: UniqueVisitDetail) =>
        row.visits.some((v) => isBot(v.page)) ? <strong className={styles.fade}>BOT</strong> : <Rank rank={getRank(row)} />,
    },
    {
      id: 'time',
      render: (row: UniqueVisitDetail) => (
        <div className={styles.nowrap}>
          {Time.formatTime(row.visits[0].createdAt)} <span className={styles.fade}>:{Time.formatTime(row.visits[0].createdAt, 'ss')}</span>
        </div>
      ),
    },
    {
      id: 'pages',
      render: (row: UniqueVisitDetail) => (
        <div>
          {row.count} <span className={styles.fade}>{row.count === 1 ? 'page' : 'pages'}</span>
        </div>
      ),
    },
    { id: 'ip', render: (row: UniqueVisitDetail) => row.visits[0].ip },
    {
      id: 'device',
      render: (row: UniqueVisitDetail) => {
        const info = Referrer.parseUserAgent(row.visits[0].userAgent);

        if (!info)
          return (
            <Tooltip title={row.visits[0].userAgent}>
              <div>Unknown</div>
            </Tooltip>
          );

        return (
          <div className={styles.device}>
            {info.deviceType && (
              <div>
                {info.deviceType}{' '}
                <span className={styles.fade}>
                  {info.os} {info.osVersion}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: 'browser',
      render: (row: UniqueVisitDetail) => {
        const info = Referrer.parseUserAgent(row.visits[0].userAgent);

        if (!info)
          return (
            <Tooltip title={row.visits[0].userAgent}>
              <div>Unknown</div>
            </Tooltip>
          );

        return (
          <div className={styles.device}>
            {info.browser && (
              <div>
                {info.browser} <span className={styles.fade}>{info.browserVersion}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: 'app',
      render: (row: UniqueVisitDetail) => {
        const info = Referrer.parseUserAgent(row.visits[0].userAgent);

        if (!info)
          return (
            <Tooltip title={row.visits[0].userAgent}>
              <div>Unknown</div>
            </Tooltip>
          );

        return (
          <div className={styles.device}>
            {info.app && (
              <div>
                {info.app} <span className={styles.fade}>{info.appVersion}</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      id: 'duration',
      render: (row: UniqueVisitDetail) => {
        const relevantVisits = row.visits.filter((v) => v.relevant !== false);
        const start = new Date(relevantVisits[0].createdAt);
        const end = new Date(relevantVisits[relevantVisits.length - 1].createdAt);

        const diff = differenceInMinutes(end, start);

        if (row.visits.length === 1) {
          return <span className={styles.fade}>One click</span>;
        }

        return (
          <div>
            {diff || 1} <span className={styles.fade}>{diff < 2 ? 'minute' : 'minutes'}</span>
          </div>
        );
      },
    },
    {
      id: 'firstPage',
      render: (row: UniqueVisitDetail) => (
        <Box display="flex">
          <div className={styles.page}>{row.visits[0].page.replace(/^\//g, '').replace(/\//g, ' - ') || 'home'}</div>
        </Box>
      ),
    },
    {
      id: 'visited',
      render: (row: UniqueVisitDetail) => {
        const uniqVisits = uniqBy(row.visits, 'page');
        return (
          <Box display="flex" flexWrap="wrap" gap="4px">
            {uniqVisits
              .filter((p) => p.page !== '/')
              .map((p) => (
                <div key={p.page} className={styles.page}>
                  {p.page.replace(/^\//g, '').replace(/\//g, ' - ')}
                </div>
              ))}
          </Box>
        );
      },
    },

    // { id: 'key', render: (row: UniqueVisitDetail) => <span className={styles.ident}>{row.identifier}</span> },
  ];

  const day = data?.sessionsByDate?.find((d) => d.day === selectedDay) ?? null;

  return (
    <div className={styles.admin}>
      <div className={styles.toolbar}>
        <div className={styles.header}>
          <div className={styles.title}>Visits</div>
          <Box display="flex" alignItems="center" gap="1px">
            <ButtonBase classes={{ root: styles.logoutButton }}>{user?.name}</ButtonBase>
            <ButtonBase classes={{ root: styles.logoutIconButton }} onClick={() => onLogout()}>
              <PowerSettingsNew className={styles.logoutIcon} />
            </ButtonBase>
          </Box>
        </div>
        <div className={styles.subHeader}>
          <div className={styles.sectionPart}>
            <Fade in={!!selectedDay}>
              <IconButton onClick={() => setSelectedDay(null)}>
                <ArrowBack />
              </IconButton>
            </Fade>
          </div>
          <div className={styles.sectionTitle}>{selectedDay ? format(new Date(selectedDay), 'dd.MM.yyyy') : 'Days overview'}</div>
          <div className={styles.sectionPart}>
            <Fade in={!!selectedDay}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={showRelevant}
                    onChange={(e) => {
                      setShowRelevant(e.target.checked);
                      localStorage.setItem('adminVisitsShowRelevant', e.target.checked.toString());
                    }}
                    name="relevantOnly"
                  />
                }
                label="Relevant visitors"
              />
            </Fade>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.dataGridWrap}>
          {!selectedDay &&
            (data?.sessionsByDate ? (
              <DataGrid<SessionByDate> data={data.sessionsByDate} config={config} onRowClick={(row) => setSelectedDay(row.day)} />
            ) : (
              <Box sx={{ minHeight: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={30} color="secondary" />
              </Box>
            ))}
          {selectedDay && day?.uniqueVisitDetails && (
            <DataGrid<UniqueVisitDetail>
              data={day.uniqueVisitDetails.filter((r) => (showRelevant ? getRank(r) : true))}
              config={dayConfig}
              onRowClick={(row) => {
                setDetail(row);
                setIsDetailOpen(true);
              }}
            />
          )}
        </div>
        <div className={styles.foot}>
          <Button onClick={() => history.push('/')}>Go to web</Button>
        </div>
      </div>
      <Dialog open={isDetailOpen} onClose={() => setIsDetailOpen(false)}>
        <div className={styles.dialogContent}>
          <div className={styles.dialogHeader}>
            <div className={styles.sectionTitle}>VISIT DETAIL</div>
          </div>
          <DataGrid
            data={detail?.visits || []}
            config={[
              {
                id: 'time',
                render: (row) => (
                  <div className={classNames(row.relevant === false && styles.notRelevant)}>{format(new Date(row.createdAt), 'HH:mm:ss')}</div>
                ),
              },
              { id: 'page', render: (row) => <div className={classNames(row.relevant === false && styles.notRelevant)}>{row.page}</div> },
              {
                id: 'spent',
                render: (row) => {
                  const nextVisit = detail?.visits.find((v) => new Date(v.createdAt) > new Date(row.createdAt));

                  if (!nextVisit) return <div />;

                  const secondsDiff = differenceInSeconds(new Date(nextVisit.createdAt), new Date(row.createdAt));

                  if (secondsDiff > 60) {
                    return (
                      <div className={classNames(nextVisit.relevant === false && styles.notRelevant)}>
                        {' '}
                        {Math.floor(secondsDiff / 60)} <span className={styles.fade}>minutes</span>
                      </div>
                    );
                  }

                  return (
                    <div className={classNames(nextVisit.relevant === false && styles.notRelevant)}>
                      {' '}
                      {differenceInSeconds(new Date(nextVisit.createdAt), new Date(row.createdAt))} <span className={styles.fade}>seconds</span>
                    </div>
                  );
                },
              },
            ]}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default Admin;
